<template>
  <div class="container m-5">
    <div class="row">
      <div class="col-1 d-flex align-items-center justify-content-end">
        <b-button
          v-if="categories.length > 5"
          variant="primary"
          size="sm"
          :disabled="scrollPosition === 0"
          @click="scrollLeft"
        >
          <i class="fas fa-chevron-left"></i>
        </b-button>
      </div>
      <div class="col-10 position-relative">
        <div class="category-list">
            <div
              v-for="(category, index) in visibleItems"
              :key="index"
              @click="selectCategory(category.id)"
              :class="{
              'category-item': true, 'selected-category': isSelectedCategory(category, index),
               }"
            >
              <p class="category-title">{{ category.categoryName }}</p>
              <div>
                <span>Tổng : </span>
                <span class="font-weight-bold">{{ category.productCount }}</span>
              </div>
              <div>
                <span>Đang kiểm : </span>
                <span class="font-weight-bold">{{ category.checkingQuantity }}</span>
              </div>
              <div>
                <span>Đã kiểm : </span>
                <span class="font-weight-bold">{{ category.checkedQuantity }}</span>
              </div>
              <div v-if="category.productOutsideCount">
                <span>SP bên ngoài : </span>
                <span class="font-weight-bold">{{ category.productOutsideCount }}</span>
              </div>
            </div>
        </div>
      </div>
      <div class="col-1 d-flex align-items-center justify-content-start">
        <b-button
          v-if="categories.length > 5"
          variant="primary"
          size="sm"
          :disabled="scrollPosition >= maxScrollPosition"
          @click="scrollRight"
        >
          <i class="fas fa-chevron-right"></i>
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton } from 'bootstrap-vue';

export default {
  components: {
    BButton,
  },
  props: {
    categories: {
      type: Array,
      required: true,
    },
    isBusy:{
      type: Boolean,
      required: true,
    }
  },
  watch: {
    categories: {
      immediate: false,
      handler(newVal) {
        console.log('change to categoies.....')
        if (!newVal) return;
        this.maxScrollPosition = Math.max(0, newVal.length - 5);
        this.updateVisibleItems();
      },
    },
  },
  data() {
    return {
      scrollPosition: 0,
      maxScrollPosition: 0,
      visibleItems: [],
      selectedCategoryId: null,
    };
  },
  methods: {
    isSelectedCategory(category, index) {
      if(this.isBusy) return;
      return (
        category.id === this.selectedCategoryId ||
        (!this.selectedCategoryId && index === 0)
      );
    },
    scrollLeft() {
      this.scrollPosition = Math.max(0, this.scrollPosition - 1);
      this.updateVisibleItems();
    },
    scrollRight() {
      this.scrollPosition = Math.min(
        this.maxScrollPosition,
        this.scrollPosition + 1,
      );
      this.updateVisibleItems();
    },
    updateVisibleItems() {
      this.visibleItems = this.categories.slice(
        this.scrollPosition,
        this.scrollPosition + 5,
      );
    },
    selectCategory(categoryId) {
      this.selectedCategoryId = categoryId;
      this.$emit('category-selected', categoryId);
    },
  },
};
</script>

<style scoped>
.row {
  min-height: 100px;
}
.category-list {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.category-item {
  width: 20%;
  padding: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
}

.category-title {
  font-size: 1rem;
  font-weight: bold;
  margin: 0 0 3px 0;
}

.selected-category {
  background-color: #007bff;
  /* Màu primary của Bootstrap */
  color: #fff;
  /* Màu chữ trắng */
}

.scroll-button {
  width: 30px;
  height: 30px;
  font-size: 12px;
}
</style>
